import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHandsHelping, faPaw, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion'; // Importa Framer Motion
import { getSettings, getHighlightedRescues, sendContactMessage } from '../services/api';
import RescueHighlight from './RescueHighlight';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

const Home = () => {
  const [settings, setSettings] = useState({});
  const [rescues, setRescues] = useState([]);
  const navigate = useNavigate(); // Inicializa el hook para redirecciones
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [status, setStatus] = useState({ loading: false, success: false, error: false });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ loading: true, success: false, error: false });

    try {
      await sendContactMessage(formData);
      setStatus({ loading: false, success: true, error: false });
      setFormData({ name: '', email: '', message: '' }); // Limpia el formulario
    } catch (error) {
      setStatus({ loading: false, success: false, error: true });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const settingsData = await getSettings();
        setSettings(
          settingsData.reduce((acc, item) => ({ ...acc, [item.setting_key]: item.setting_value }), {})
        );

        const rescuesData = await getHighlightedRescues();
        setRescues(rescuesData.slice(-4)); // Mostrar los últimos 4 destacados.
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div id="home" className="section">
      {/* Hero Section */}
      <section
        className="relative flex items-center justify-center md:justify-start h-screen bg-cover bg-center text-white"
        style={{
          backgroundImage: `linear-gradient(rgba(74, 20, 140, 0.2), rgba(74, 20, 140, 0.2)), url(${settings.header_image})`,
        }}
      >
        <div className="relative z-10 text-center md:text-left max-w-lg px-6 md:ml-12 space-y-6">
          {/* Añadimos animación al título y texto */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-4">
              {settings.title_text || 'Fundación Valkiria'}
            </h1>
            <p className="text-lg md:text-xl">
              {settings.presentation_text ||
                ''}
            </p>
          </motion.div>

          {/* Añadimos animación al botón */}
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="mt-4"
          >
            <button
              className="inline-flex items-center bg-purple-600 hover:bg-purple-700 text-white font-semibold px-4 py-2 rounded-lg justify-center w-48"
              onClick={() => navigate('/donation')} // Redirige a la página de donaciones
            >
              <FontAwesomeIcon icon={faHeart} className="mr-2" />
              Donar
            </button>
          </motion.div>
        </div>
        <div className="absolute inset-0 bg-black bg-opacity-20 z-0"></div>
      </section>

      {/* Featured Cases Section */}
      <section className="py-16 bg-gray-100">
        <h2 className="text-center text-3xl font-semibold text-purple-800 mb-8">
          Algunos de Nuestros Michis
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 px-4">
          {rescues.map((rescue) => (
            <RescueHighlight key={rescue.id} rescue={rescue} />
          ))}
        </div>
      </section>

      {/* Nueva Sección: Nosotros */}
      <section className="py-8 bg-white">
        <div className="max-w-5xl mx-auto px-6 text-center">
          <h2 className="text-3xl font-semibold text-purple-800 mb-6">Nosotros</h2>
          <p className="text-lg text-gray-700">
            En <span className="font-bold">Fundación Valkiria</span>, somos una organización sin fines de lucro dedicada al rescate y rehabilitación de gatos abandonados. 
            Nacimos del amor y respeto por los animales, y de la convicción de que cada felino merece una vida digna y llena de cariño. 
            Nuestro equipo está compuesto por voluntarios comprometidos que trabajan incansablemente para ofrecer una segunda oportunidad a aquellos gatos que han sido olvidados o maltratados.
          </p>
        </div>
      </section>

      {/* Nueva Sección: Nuestra Misión */}
      <section className="py-8 bg-white">
        <div className="max-w-5xl mx-auto px-6 text-center">
          <h2 className="text-3xl font-semibold text-purple-800 mb-6">Nuestra Misión</h2>
          <p className="text-lg text-gray-700">
            Promover el bienestar y rescate de gatos en situación de calle brindándoles
            atención médica, refugio y una segunda oportunidad de vida; al mismo tiempo que
            fomentamos la educación y concientización sobre el respeto y cuidado de los animales en
            la comuna de Antofagasta.
          </p>
        </div>
      </section>
      
      {/* Nueva Sección: Nuestra Visión */}
      <section className="py-8 bg-white">
        <div className="max-w-5xl mx-auto px-6 text-center">
          <h2 className="text-3xl font-semibold text-purple-800 mb-6">Nuestra Visión</h2>
          <p className="text-lg text-gray-700">
            Ser un referente en la protección y bienestar de los gatos, promoviendo una sociedad más compasiva y responsable con los animales. 
            Aspiramos a reducir el abandono y fomentar la adopción y la tenencia responsable en nuestra comunidad.
          </p>
        </div>
      </section>

      {/* Nueva Sección: Cómo Ayudar */}
      <section
        className="py-16"
        style={{
          background: 'linear-gradient(to right, #4a148c, #7b1fa2)',
        }}
      >
        <div className="max-w-7xl mx-auto px-6 text-white">
          <h2 className="text-center text-3xl font-semibold mb-8">Cómo Puedes Ayudar</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Donar */}
            <div className="text-center">
              <FontAwesomeIcon icon={faHeart} className="text-6xl mb-4" />
              <h3 className="text-2xl font-semibold mb-2">Donar</h3>
              <p className="text-lg">
                Tu aporte económico nos ayuda a cubrir gastos médicos, alimentación y mantenimiento del refugio.
              </p>
            </div>
            {/* Adoptar */}
            <div className="text-center">
              <FontAwesomeIcon icon={faPaw} className="text-6xl mb-4" />
              <h3 className="text-2xl font-semibold mb-2">Adoptar</h3>
              <p className="text-lg">
                Dale un hogar lleno de amor a uno de nuestros gatos rescatados. ¡Ellos te están esperando!
              </p>
            </div>
            {/* Voluntariado */}
            <div className="text-center">
              <FontAwesomeIcon icon={faHandsHelping} className="text-6xl mb-4" />
              <h3 className="text-2xl font-semibold mb-2">Ser Voluntario</h3>
              <p className="text-lg">
                Únete a nuestro equipo y contribuye con tu tiempo y habilidades para mejorar la vida de estos felinos.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Nueva Sección: Testimonios */}
      {/*
      <section className="py-16 bg-gray-100">
        <div className="max-w-5xl mx-auto px-6">
          <h2 className="text-center text-3xl font-semibold text-purple-800 mb-8">Historias de Éxito</h2>
          <div className="space-y-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <p className="text-gray-700 italic">
                "Adoptar a Luna ha sido una de las mejores decisiones de mi vida. Gracias a Fundación Valkiria por traer tanta alegría a nuestro hogar."
              </p>
              <p className="text-right text-purple-800 font-semibold mt-4">- María López</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <p className="text-gray-700 italic">
                "Ser voluntario en la fundación me ha permitido ayudar y aprender sobre el cuidado de los gatos. Es una experiencia muy gratificante."
              </p>
              <p className="text-right text-purple-800 font-semibold mt-4">- Carlos Pérez</p>
            </div>
          </div>
        </div>
      </section>
      */}

      {/* Nueva Sección: Contacto */}
      <section className="py-16 bg-gray-100">
        <div className="max-w-5xl mx-auto px-6">
          <h2 className="text-center text-3xl font-semibold text-purple-800 mb-8">Contáctanos</h2>
          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-1/2 md:pr-8">
              <p className="text-lg text-gray-700 mb-8">
                Si tienes alguna pregunta o deseas más información sobre cómo ayudar, no dudes en contactarnos.
              </p>
              <div className="flex items-center mb-8">
                <FontAwesomeIcon icon={faEnvelope} className="text-purple-800 text-2xl mr-3" />
                <p className="text-lg text-gray-700">contacto@fundacionvalkiria.cl</p>
              </div>
              <p className="text-lg text-gray-700 mb-14">
                O escribenos a cualquiera de nuestras redes sociales.
              </p>
              {/* Puedes agregar más información de contacto aquí */}
            </div>
            {/* Formulario de Contacto (opcional) */}
            {/* Si deseas agregar un formulario de contacto, descomenta el siguiente bloque y asegúrate de manejar el estado y envío del formulario */}
            <div className="md:w-1/2 mt-8 md:mt-0">
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Nombre"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Correo Electrónico"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
                    required
                  />
                </div>
                <div className="mb-4">
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Mensaje"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
                    rows="5"
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="bg-purple-600 text-white font-semibold px-6 py-2 rounded-lg hover:bg-purple-700 transition duration-300"
                  disabled={status.loading}
                >
                  {status.loading ? 'Enviando...' : 'Enviar Mensaje'}
                </button>
                {status.success && (
                  <p className="mt-4 text-green-600">Mensaje enviado con éxito. ¡Gracias por contactarnos!</p>
                )}
                {status.error && (
                  <p className="mt-4 text-red-600">Hubo un error al enviar tu mensaje. Inténtalo nuevamente.</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
