import React, { useEffect, useState } from 'react';
import { getAdmins, createAdmin, deleteAdmin } from '../services/api';

const AdminUsers = () => {
  const [admins, setAdmins] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newAdmin, setNewAdmin] = useState({ username: '', email: '', password: '' });

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const data = await getAdmins();
        setAdmins(data);
      } catch (error) {
        console.error('Error fetching admins:', error);
      }
    };

    fetchAdmins();
  }, []);

  const handleCreateAdmin = async () => {
    try {
      await createAdmin(newAdmin);
      alert('Admin creado exitosamente');
      setNewAdmin({ username: '', email: '', password: '' });
      setModalOpen(false); // Cierra el modal
      const updatedAdmins = await getAdmins(); // Refresca la lista de admins
      setAdmins(updatedAdmins);
    } catch (error) {
      console.error('Error creating admin:', error);
    }
  };

  const handleDeleteAdmin = async (id) => {
    try {
      await deleteAdmin(id);
      alert('Admin eliminado');
      const updatedAdmins = await getAdmins(); // Refresca la lista de admins
      setAdmins(updatedAdmins);
    } catch (error) {
      console.error('Error deleting admin:', error);
    }
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Gestión de Usuarios</h1>

      {/* Lista de Admins */}
      <div className="space-y-4">
        {admins.map((admin) => (
          <div key={admin.id} className="flex justify-between bg-white p-4 rounded shadow">
            <p>{admin.username} ({admin.email})</p>
            <button
              onClick={() => handleDeleteAdmin(admin.id)}
              className="text-red-500 hover:underline"
            >
              Eliminar
            </button>
          </div>
        ))}
      </div>

      {/* Botón para Abrir el Modal */}
      <div className="mt-6">
        <button
          onClick={() => setModalOpen(true)}
          className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
        >
          Crear Nuevo Usuario
        </button>
      </div>

      {/* Modal */}
      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg relative">
            <h2 className="text-xl font-bold mb-4">Crear Nuevo Usuario</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="username" className="block text-gray-700 font-semibold mb-2">
                  Usuario
                </label>
                <input
                  id="username"
                  type="text"
                  placeholder="Usuario"
                  value={newAdmin.username}
                  onChange={(e) => setNewAdmin({ ...newAdmin, username: e.target.value })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">
                  Correo
                </label>
                <input
                  id="email"
                  type="email"
                  placeholder="Correo"
                  value={newAdmin.email}
                  onChange={(e) => setNewAdmin({ ...newAdmin, email: e.target.value })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500"
                />
              </div>
              <div className="md:col-span-2">
                <label htmlFor="password" className="block text-gray-700 font-semibold mb-2">
                  Contraseña
                </label>
                <input
                  id="password"
                  type="password"
                  placeholder="Contraseña"
                  value={newAdmin.password}
                  onChange={(e) => setNewAdmin({ ...newAdmin, password: e.target.value })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500"
                />
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setModalOpen(false)}
                className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded-lg"
              >
                Cancelar
              </button>
              <button
                onClick={handleCreateAdmin}
                className="bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-lg"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminUsers;
