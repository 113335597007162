import React, { useState, useEffect } from 'react';
import { getRescues, createRescue, updateRescue, deleteRescue } from '../services/api';

const RescuesManagement = () => {
  const [rescues, setRescues] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentRescue, setCurrentRescue] = useState(null);
  const [previewImage, setPreviewImage] = useState('');
  const [imageFile, setImageFile] = useState(null); // Añadir estado para la imagen

  useEffect(() => {
    const fetchRescues = async () => {
      try {
        const data = await getRescues();
        setRescues(data);
      } catch (error) {
        console.error('Error fetching rescues:', error);
      }
    };

    fetchRescues();
  }, []);

  const closeModal = () => {
    setModalOpen(false);
    setCurrentRescue(null);
    setPreviewImage('');
    setImageFile(null); // Resetear imagen al cerrar el modal
  };

  const handleSave = async () => {
    try {
      let rescueData = { ...currentRescue };
      if (!currentRescue.id) {
        await createRescue(rescueData, imageFile); // Enviar imagen en la creación
      } else {
        await updateRescue(currentRescue.id, rescueData, imageFile); // Enviar imagen en la actualización
      }
      const updatedRescues = await getRescues();
      setRescues(updatedRescues);
      closeModal();
    } catch (error) {
      console.error('Error saving rescue:', error);
    }
  };
  
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    setPreviewImage(URL.createObjectURL(file)); // Mostrar previsualización de la imagen
  };
  

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar este caso? Esta acción no se puede deshacer.');
    if (confirmDelete) {
      try {
        await deleteRescue(id);
        setRescues(await getRescues());
      } catch (error) {
        console.error('Error deleting rescue:', error);
      }
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Casos Rescatados</h2>
      <button
        onClick={() => {
          setModalOpen(true);
          setCurrentRescue({
            name: '',
            age: '',
            vaccination_status: false,
            dewormed: false,
            sponsored: false,
            surgery_details: '',
            personality: '',
            story: '',
            image_url: '',
            sterilized: false,
          });
        }}
        className="mb-4 bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-lg"
      >
        Crear Nuevo Caso
      </button>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {rescues.map((rescue) => (
          <div key={rescue.id} className="bg-white shadow-lg rounded-lg p-4">
            <img src={rescue.image_url} alt={rescue.name} className="w-full h-40 object-cover rounded-t-lg" />
            <h3 className="text-lg font-bold mt-2">{rescue.name}</h3>
            <p className="text-gray-600 text-sm">{rescue.story}</p>
            <div className="mt-4 flex justify-between">
              <button
                onClick={() => {
                  setModalOpen(true);
                  setCurrentRescue(rescue);
                  setPreviewImage(rescue.image_url);
                }}
                className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-3 rounded-lg"
              >
                Editar
              </button>
              <button
                onClick={() => handleDelete(rescue.id)}
                className="bg-red-600 hover:bg-red-700 text-white py-1 px-3 rounded-lg"
              >
                Eliminar
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {modalOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto">
        <div
          className="bg-white w-full max-w-lg md:max-w-3xl p-6 rounded-lg shadow-lg relative max-h-screen overflow-y-auto"
        >
          <h3 className="text-xl font-bold mb-4">
            {currentRescue.id ? 'Editar Caso' : 'Crear Caso'}
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">
                  Nombre
                </label>
                <input
                  id="name"
                  type="text"
                  placeholder="Nombre"
                  value={currentRescue.name}
                  onChange={(e) => setCurrentRescue((prev) => ({ ...prev, name: e.target.value }))}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500"
                />
              </div>
              <div>
                <label htmlFor="age" className="block text-gray-700 font-semibold mb-2">
                  Edad
                </label>
                <input
                  id="age"
                  type="number"
                  placeholder="Edad"
                  value={currentRescue.age}
                  onChange={(e) => setCurrentRescue((prev) => ({ ...prev, age: e.target.value }))}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500"
                />
              </div>
              <div className="md:col-span-2">
                <label htmlFor="surgery_details" className="block text-gray-700 font-semibold mb-2">
                  Detalle Veterinario
                </label>
                <textarea
                  id="surgery_details"
                  placeholder="Detalle Veterinario"
                  value={currentRescue.surgery_details}
                  onChange={(e) => setCurrentRescue((prev) => ({ ...prev, surgery_details: e.target.value }))}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500"
                />
              </div>
              <div>
                <label htmlFor="personality" className="block text-gray-700 font-semibold mb-2">
                  Personalidad
                </label>
                <textarea
                  id="personality"
                  placeholder="Personalidad"
                  value={currentRescue.personality}
                  onChange={(e) => setCurrentRescue((prev) => ({ ...prev, personality: e.target.value }))}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500"
                />
              </div>
              <div className="md:col-span-2">
                <label htmlFor="story" className="block text-gray-700 font-semibold mb-2">
                  Historia
                </label>
                <textarea
                  id="story"
                  placeholder="Historia"
                  value={currentRescue.story}
                  onChange={(e) => setCurrentRescue((prev) => ({ ...prev, story: e.target.value }))}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500"
                />
              </div>
              <div className="md:col-span-2">
                <label htmlFor="image_url" className="block text-gray-700 font-semibold mb-2">
                  Imagen
                </label>
                <input
                  id="image_url"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500"
                />
                {previewImage && (
                  <img
                    src={previewImage}
                    alt="Previsualización"
                    className="w-full h-40 object-cover rounded-lg mt-2"
                  />
                )}
              </div>
              <div className="flex gap-4 md:col-span-2">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={currentRescue.vaccination_status}
                    onChange={(e) => setCurrentRescue((prev) => ({ ...prev, vaccination_status: e.target.checked }))}
                    className="form-checkbox text-purple-600"
                  />
                  Vacunado
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={currentRescue.dewormed}
                    onChange={(e) => setCurrentRescue((prev) => ({ ...prev, dewormed: e.target.checked }))}
                    className="form-checkbox text-purple-600"
                  />
                  Desparasitado
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={currentRescue.sterilized}
                    onChange={(e) => setCurrentRescue((prev) => ({ ...prev, sterilized: e.target.checked }))}
                    className="form-checkbox text-purple-600"
                  />
                  Esterilización
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={currentRescue.sponsored}
                    onChange={(e) => setCurrentRescue((prev) => ({ ...prev, sponsored: e.target.checked }))}
                    className="form-checkbox text-purple-600"
                  />
                  Padrino
                </label>
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={closeModal}
                className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded-lg"
              >
                Cancelar
              </button>
              <button
                onClick={handleSave}
                className="bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-lg"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RescuesManagement;
