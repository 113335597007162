import React, { useEffect, useState } from 'react';
import { getHighlightedRescues } from '../services/api';
import RescueCard from './RescueCard';
import FilterBar from './FilterBar';
import { motion } from 'framer-motion';

const RescueList = () => {
  const [rescues, setRescues] = useState([]);
  const [filteredRescues, setFilteredRescues] = useState([]);

  useEffect(() => {
    const fetchRescues = async () => {
      try {
        const rescuesData = await getHighlightedRescues();
        setRescues(rescuesData);
        setFilteredRescues(rescuesData); // Inicializar la lista filtrada
      } catch (error) {
        console.error('Error fetching rescues:', error);
      }
    };

    fetchRescues();
  }, []);

  const handleFilterChange = (filters) => {
    const filtered = rescues.filter((rescue) => {
      const matchesSponsored =
        filters.sponsored === 'all'
          ? true
          : filters.sponsored === 'true'
          ? rescue.sponsored
          : !rescue.sponsored;

      return (
        (filters.vaccinated ? rescue.vaccination_status : true) &&
        (filters.dewormed ? rescue.dewormed : true) &&
        matchesSponsored &&
        (filters.age ? rescue.age === parseInt(filters.age) : true)
      );
    });
    setFilteredRescues(filtered);
  };

  return (
    <div className="relative">
      {/* Filtro desplegable */}
      <div className="absolute top-[-60px] right-4 z-20">
        {/* Ajustamos la posición hacia arriba con `top-[-60px]` */}
        <FilterBar onFilterChange={handleFilterChange} />
      </div>

      {/* Lista de rescates */}
      <motion.div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 px-4 mt-12"
        // Aumentamos el margen superior con `mt-12` para compensar el movimiento hacia arriba
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: {
              staggerChildren: 0.2,
            },
          },
        }}
      >
        {filteredRescues.map((rescue) => (
          <RescueCard key={rescue.id} rescue={rescue} />
        ))}
      </motion.div>
    </div>
  );
};

export default RescueList;
