import React from 'react';
import RescueList from '../components/RescueList';
import Breadcrumbs from '../components/Breadcrumbs';

const RescuedPage = () => {
  return (
    <div className="mt-16 bg-gray-100 min-h-screen py-8">
      <div className="px-4 py-2">
        <Breadcrumbs />
      </div>
      <h1 className="text-4xl font-bold text-center text-purple-800 mb-6">
      ¡Adopta o Apadrina y Cambia una Vida!
      </h1>
      <p className='text-2xl font-bold text-center text-gray-500 mb-2'>
        En esta sección encontrarás a los adorables gatos que actualmente están bajo nuestro cuidado y que buscan un hogar lleno de amor. 
        Cada uno de ellos tiene una historia única y está ansioso por ser parte de una familia.
      </p>
      <p className='text-3xl font-bold text-center text-gray-500 mb-6'>
        ¡Conoce a nuestros michis disponibles para adopción!
      </p>
      <RescueList />
    </div>
  );
};

export default RescuedPage;