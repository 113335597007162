import React, { useEffect, useState } from 'react';
import { getSettings, updateSetting, deleteSetting, createSetting } from '../services/api';

const SettingsManagement = () => {
  const [settings, setSettings] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newSetting, setNewSetting] = useState({
    setting_key: '',
    setting_value: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [currentEditId, setCurrentEditId] = useState(null);
  const [imageFile, setImageFile] = useState(null); // Añadir estado para imagen

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await getSettings();
        setSettings(data);
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };

    fetchSettings();
  }, []);

  const handleCreateOrUpdate = async () => {
    try {
      const settingData = { ...newSetting };
      if (isEditing) {
        await updateSetting(currentEditId, settingData, imageFile); // Enviar imagen en la actualización
      } else {
        await createSetting(settingData, imageFile); // Enviar imagen en la creación
      }
      alert(isEditing ? 'Configuración actualizada' : 'Nueva configuración creada');
      const updatedSettings = await getSettings();
      setSettings(updatedSettings);
      setNewSetting({ setting_key: '', setting_value: '' });
      setIsEditing(false);
      setModalOpen(false);
      setImageFile(null); // Resetear archivo al cerrar modal
    } catch (error) {
      console.error('Error saving setting:', error);
    }
  };
  
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    setNewSetting((prev) => ({
      ...prev,
      setting_value: file.name, // Actualizar valor para mostrar el nombre
    }));
  };

  const handleEdit = (setting) => {
    setNewSetting(setting);
    setIsEditing(true);
    setCurrentEditId(setting.id);
    setModalOpen(true); // Abrir modal
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar esta configuración? Esta acción no se puede deshacer.');
    if (confirmDelete) {
      try {
        await deleteSetting(id);
        alert('Configuración eliminada');
        const updatedSettings = await getSettings(); // Refrescar lista
        setSettings(updatedSettings);
      } catch (error) {
        console.error('Error deleting setting:', error);
      }
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Gestión de Configuración</h1>
      
      {/* Botón para abrir el modal */}
      <div className="mt-4 mb-4">
        <button
          onClick={() => {
            setModalOpen(true);
            setNewSetting({ setting_key: '', setting_value: '' });
            setIsEditing(false);
            setCurrentEditId(null);
          }}
          className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
        >
          Crear Nueva Configuración
        </button>
      </div>

      {/* Lista de configuraciones */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {settings.map((setting) => (
          <div key={setting.id} className="flex flex-col justify-between bg-white p-4 rounded shadow">
            <div>
              <p className="font-semibold break-words">{setting.setting_key}</p>
              <p className="text-sm text-gray-600 break-words truncate">{setting.setting_value}</p>
            </div>
            <div className="mt-4 flex justify-between">
              <button
                onClick={() => handleEdit(setting)}
                className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-3 rounded-lg"
              >
                Editar
              </button>
              <button
                onClick={() => handleDelete(setting.id)}
                className="bg-red-600 hover:bg-red-700 text-white py-1 px-3 rounded-lg"
              >
                Eliminar
              </button>
            </div>
          </div>
        ))}
      </div>


      {/* Modal */}
      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto">
          <div
            className="bg-white w-full max-w-sm sm:max-w-md md:max-w-lg p-4 sm:p-6 rounded-lg shadow-lg relative max-h-screen overflow-y-auto"
          >
            <h3 className="text-xl font-bold mb-4">
              {isEditing ? 'Editar Configuración' : 'Crear Configuración'}
            </h3>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <label htmlFor="setting_key" className="block text-gray-700 font-semibold mb-2">
                  Clave
                </label>
                <input
                  id="setting_key"
                  type="text"
                  placeholder="Clave de Configuración"
                  value={newSetting.setting_key}
                  onChange={(e) => setNewSetting({ ...newSetting, setting_key: e.target.value })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500"
                />
              </div>
              <div>
                <label htmlFor="setting_value" className="block text-gray-700 font-semibold mb-2">
                  Valor
                </label>
                <input
                  id="setting_value"
                  type="text"
                  placeholder="Valor de Configuración"
                  value={newSetting.setting_value}
                  onChange={(e) => setNewSetting({ ...newSetting, setting_value: e.target.value })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500"
                />
              </div>
              {newSetting.setting_key.includes('image') && (
                <div>
                  <label htmlFor="image" className="block text-gray-700 font-semibold mb-2">
                    Subir Imagen
                  </label>
                  <input
                    id="image"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500"
                  />
                </div>
              )}
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setModalOpen(false)}
                className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded-lg"
              >
                Cancelar
              </button>
              <button
                onClick={handleCreateOrUpdate}
                className="bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-lg"
              >
                {isEditing ? 'Guardar Cambios' : 'Crear'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsManagement;
