import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import AdminUsers from '../components/AdminUsers';
import RescuesManagement from '../components/RescuesManagement';
import SettingsManagement from '../components/SettingsManagement';

const Dashboard = () => {
  const [currentSection, setCurrentSection] = useState('users'); // Sección activa
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false); // Estado de colapso del sidebar

  // Renderiza la sección seleccionada
  const renderSection = () => {
    switch (currentSection) {
      case 'users':
        return <AdminUsers />;
      case 'rescues':
        return <RescuesManagement />;
      case 'settings':
        return <SettingsManagement />;
      default:
        return <AdminUsers />;
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Sidebar */}
      <Sidebar
        setCurrentSection={setCurrentSection}
        isCollapsed={isSidebarCollapsed}
        setIsCollapsed={setIsSidebarCollapsed}
      />
      {/* Contenido principal */}
      <div
        className={`flex-1 p-6 transition-all duration-300 ${
          isSidebarCollapsed ? 'ml-20' : 'ml-64'
        }`}
      >
        {renderSection()}
      </div>
    </div>
  );
};

export default Dashboard;
