import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStethoscope, faUtensils, faHome, faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { getSettings } from '../services/api';
import Breadcrumbs from '../components/Breadcrumbs';

const DonationPage = () => {
  const [donationLinks, setDonationLinks] = useState([]);
  const [logos, setLogos] = useState({});
  const [reasons, setReasons] = useState([]);
  const [donationsImage, setDonationsImage] = useState(''); // Imagen principal

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getSettings();

        const donations = settings.filter((item) => item.setting_key.startsWith('donation-'));

        const donationData = donations.map((item) => ({
          key: item.setting_key.split('-')[1],
          link: item.setting_value,
        }));

        const logosData = settings.reduce((acc, item) => {
          if (item.setting_key.startsWith('logo-')) {
            const key = item.setting_key.split('-')[1];
            acc[key] = item.setting_value;
          }
          return acc;
        }, {});

        // Filtrar las razones con imágenes
        const reasonsData = settings
          .filter((item) => item.setting_key.endsWith('-image'))
          .map((item) => ({
            key: item.setting_key.split('-image')[0], // Nombre de la razón
            image: item.setting_value,
            text: settings.find((s) => s.setting_key === item.setting_key.replace('-image', ''))?.setting_value || '', // Buscar texto asociado
          }));

          // Obtener la imagen principal de donaciones
        const donationImageSetting = settings.find(
          (item) => item.setting_key === 'donations_image'
        );

        setDonationsImage(donationImageSetting?.setting_value || '');
        setDonationLinks(donationData);
        setLogos(logosData);
        setReasons(reasonsData);
      } catch (error) {
        console.error('Error fetching donation settings:', error);
      }
      
    };

    fetchSettings();
  }, []);

  return (
    <div className="text-white min-h-screen">
      {/* Agradecimiento */}
      <div className="relative py-16 lg:py-24 bg-white">
      <div className="px-4 py-2">
        <Breadcrumbs />
      </div>
        <div className="max-w-8xl mx-auto grid lg:grid-cols-2 items-center gap-12 px-6 lg:px-8">
          {/* Texto a la izquierda */}
          <div className="z-10">
            <h2 className="text-4xl font-extrabold text-purple-800 mb-6">Haz la Diferencia: Dona Hoy</h2>
            <p className="text-lg text-gray-700 mb-8">
              Tu generosidad es la fuerza que impulsa nuestra misión. Las donaciones que recibimos en{' '}
              <span className="font-semibold">Fundación Valkiria</span> se destinan directamente al
              cuidado y bienestar de los gatos rescatados. Con tu ayuda, podemos proporcionar:
            </p>
            <ul className="space-y-6 mb-8">
              {/* Atención Médica de Calidad */}
              <li className="flex">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon icon={faStethoscope} className="text-secondary text-2xl mt-1" />
                </div>
                <div className="ml-4">
                  <p className="text-xl font-semibold text-gray-600">Atención Médica de Calidad</p>
                  <p className="mt-1 text-gray-700">
                    Cubrimos gastos veterinarios, vacunas, tratamientos y cirugías necesarias para
                    asegurar que cada gato esté sano y listo para ser adoptado.
                  </p>
                </div>
              </li>
              {/* Alimento y Suministros */}
              <li className="flex">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon icon={faUtensils} className="text-secondary text-2xl mt-1" />
                </div>
                <div className="ml-4">
                  <p className="text-xl font-semibold text-gray-600">Alimento y Suministros</p>
                  <p className="mt-1 text-gray-700">
                    Proporcionamos alimento nutritivo, arena sanitaria, juguetes y todo lo que
                    necesitan para estar cómodos y felices.
                  </p>
                </div>
              </li>
              {/* Un Refugio Seguro */}
              <li className="flex">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon icon={faHome} className="text-secondary text-2xl mt-1" />
                </div>
                <div className="ml-4">
                  <p className="text-xl font-semibold text-gray-600">Un Refugio Seguro</p>
                  <p className="mt-1 text-gray-700">
                    Mantenemos nuestras instalaciones en óptimas condiciones para ofrecer un ambiente
                    cálido y seguro mientras esperan su hogar definitivo.
                  </p>
                </div>
              </li>
              {/* Programas de Esterilización y Educación */}
              <li className="flex">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon icon={faUserGraduate} className="text-secondary text-2xl mt-1" />
                </div>
                <div className="ml-4">
                  <p className="text-xl font-semibold text-gray-600">
                    Programas de Esterilización y Educación
                  </p>
                  <p className="mt-1 text-gray-700">
                    Promovemos la esterilización para controlar la población felina y realizamos
                    campañas educativas sobre tenencia responsable.
                  </p>
                </div>
              </li>
            </ul>
            <p className="text-lg text-gray-700 mb-8">
              Cada aporte, por pequeño que sea, tiene un impacto significativo en la vida de estos
              animales. Al donar, estás contribuyendo a salvar vidas y a construir un futuro mejor
              para ellos.
            </p>
            <p className="text-2xl font-bold text-secondary">
              ¡Gracias por ser parte de la familia Valkiria!
            </p>
          </div>

          {/* Imagen a la derecha con degradado */}
          <div
            className="absolute right-0 top-10 bottom-0 lg:w-1/2 bg-no-repeat bg-cover bg-right"
            style={{
              backgroundImage: `linear-gradient(to left, rgba(255, 255, 255, 0) 45%, rgba(255, 255, 255, 1) 100%), url(${donationsImage})`,
            }}
          ></div>
        </div>
      </div>


      {/* Canales de Donación */}
      <section
      className="py-8"
      style={{
        background: 'linear-gradient(to right, #4a148c, #7b1fa2)',
      }}
      >
        <div className="py-6">
          <h2 className="text-3xl font-semibold text-center mb-6">Donde Donar:</h2>
          <div className="flex flex-wrap justify-center gap-6">
            {donationLinks.map((donation) => (
              <a
                key={donation.key}
                href={donation.link}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-white text-secondary rounded-lg shadow-lg p-4 w-64 flex flex-col items-center hover:shadow-xl"
              >
                {logos[donation.key] && (
                  <img src={logos[donation.key]} alt={donation.key} className="w-20 h-20" />
                )}
              </a>
            ))}
          </div>
        </div>
      </section>

      {/* Razones para Donar */}
      <div className="py-8 bg-gray-100 text-black">
        <h2 className="text-2xl text-purple-800 font-semibold text-center mb-6">¿En Qué Se Utilizan Tus Donaciones?</h2>
        
        <h3 className="text-lg font-semibold text-gray-600 text-center md:text-xl mb-6">
          En Fundación Valkiria, creemos en la transparencia y queremos que sepas cómo tu contribución ayuda directamente a los gatos que rescatamos:
        </h3>
        <div className="space-y-12">
          {reasons.map((reason, index) => (
            <div
              key={reason.key}
              className={`flex items-center ${
                index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'
              } gap-8 max-w-5xl mx-auto`}
            >
              <div className="w-1/2">
                <img
                  src={reason.image}
                  alt={reason.key}
                  className="rounded-lg shadow-lg object-cover w-full h-64"
                />
              </div>
              <div className="w-1/2">
                <h3 className="text-xl text-purple-800 font-bold mb-4 capitalize">{reason.key}</h3>
                <p className="text-gray-600">{reason.text}</p>
              </div>
            </div>
          ))}
        </div>
        <h3 className="text-lg font-semibold text-gray-600 text-center md:text-xl mt-6">
        Tu apoyo es esencial para continuar con nuestra labor. Cada donación es una inversión en una vida mejor para estos felinos. ¡Gracias por ayudarnos a marcar la diferencia!
        </h3>
      </div>
    </div>
  );
};

export default DonationPage;
