import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const RescueHighlight = ({ rescue }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true }); // Detecta si el componente está en pantalla

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}} // Solo se anima si está en pantalla
      transition={{ duration: 0.6 }}
      className="bg-white border border-purple-300 rounded-lg shadow-md overflow-hidden text-center w-full max-w-sm mx-auto transform transition-transform duration-300"
    >
      <img
        src={rescue.image_url}
        alt={rescue.name}
        className="w-full h-80 object-cover"
      />
      <div className="p-2">
        <h3 className="text-primary font-semibold text-lg">{rescue.name}</h3>
      </div>
    </motion.div>
  );
};

export default RescueHighlight;
