import React, { useState } from 'react';

const FilterBar = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({
    vaccinated: false,
    dewormed: false,
    sponsored: 'all', // Nuevo: 'all', 'true' o 'false'
    age: '',
  });
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedFilters = {
      ...filters,
      [name]: type === 'checkbox' ? checked : value,
    };
    setFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  return (
    <div className="relative">
      {/* Botón de Filtrar */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition duration-300 flex items-center gap-2"
      >
        <span className="material-icons">filter_alt</span>
        Filtrar
      </button>

      {/* Contenedor desplegable */}
      {isOpen && (
        <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-lg p-4 w-64 z-10">
          {/* Opciones de filtro */}
          <h2 className="text-lg font-bold text-gray-700 mb-4 flex items-center gap-2">
            <span className="material-icons text-purple-600">filter_list</span>
            Opciones
          </h2>

          {/* Vacunado */}
          <label className="flex items-center gap-2 mb-3">
            <span className="material-icons text-purple-500">vaccines</span>
            <span className="text-gray-800 font-medium">Vacunado</span>
            <input
              type="checkbox"
              name="vaccinated"
              checked={filters.vaccinated}
              onChange={handleChange}
              className="form-checkbox rounded-full text-purple-600 focus:ring-purple-400 transition-all duration-200 h-6 w-6"
            />
          </label>

          {/* Desparasitado */}
          <label className="flex items-center gap-2 mb-3">
            <span className="material-icons text-purple-500">bug_report</span>
            <span className="text-gray-800 font-medium">Desparasitado</span>
            <input
              type="checkbox"
              name="dewormed"
              checked={filters.dewormed}
              onChange={handleChange}
              className="form-checkbox rounded-full text-purple-600 focus:ring-purple-400 transition-all duration-200 h-6 w-6"
            />
          </label>

          {/* Padrino */}
          <div className="flex flex-col mb-4">
            <label htmlFor="sponsored" className="flex items-center gap-2">
              <span className="material-icons text-purple-500">favorite</span>
              <span className="text-gray-800 font-medium">Apadrinados</span>
            </label>
            <select
              id="sponsored"
              name="sponsored"
              value={filters.sponsored}
              onChange={handleChange}
              className="border border-gray-300 rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-purple-600 transition-all"
            >
              <option value="all">Todos</option>
              <option value="true">Apadrinados</option>
              <option value="false">No Apadrinados</option>
            </select>
          </div>

          {/* Edad */}
          <div className="flex items-center gap-2">
            <span className="material-icons text-purple-500">calendar_today</span>
            <label htmlFor="age" className="text-gray-800 font-medium">
              Edad:
            </label>
            <input
              type="number"
              id="age"
              name="age"
              value={filters.age}
              onChange={handleChange}
              placeholder="Ej. 2"
              className="border border-gray-300 rounded-lg px-2 py-1 w-20 focus:outline-none focus:ring-2 focus:ring-purple-600 transition-all"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterBar;
