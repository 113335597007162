import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyringe, faHeart, faTablets, faStethoscope, faBook, faCat, faHeartBroken, faCalendarDays, faShieldCat } from '@fortawesome/free-solid-svg-icons';
import { motion, useInView } from 'framer-motion';

const RescueCard = ({ rescue }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 30 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.6 }}
      className="relative w-full max-w-md mx-auto overflow-hidden rounded-lg shadow-lg transform transition-transform duration-300"
    >
      <img
        src={rescue.image_url}
        alt={rescue.name}
        className="w-full h-96 object-cover transition-transform duration-500 transform hover:scale-105"
      />
      <div className="absolute inset-0 bg-white bg-opacity-70 opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
        <div className="p-4 text-center">
          <h3 className="text-purple-800 font-semibold text-xl mb-2">{rescue.name}</h3>
          <ul className="space-y-2 text-gray-600 text-sm">
            <li>
              <FontAwesomeIcon icon={faCalendarDays} className="text-purple-500 mr-2" />
              Edad: {rescue.age} años
            </li>
            <li>
              <FontAwesomeIcon
                icon={faSyringe}
                className={`mr-2 ${rescue.vaccination_status ? 'text-green-500' : 'text-red-500'}`}
              />
              Vacunas: {rescue.vaccination_status ? 'Sí' : 'No'}
            </li>
            <li>
              <FontAwesomeIcon
                icon={faTablets}
                className={`mr-2 ${rescue.dewormed ? 'text-green-500' : 'text-red-500'}`}
              />
              Desparasitación: {rescue.dewormed ? 'Sí' : 'No'}
            </li>
            <li>
              <FontAwesomeIcon
                icon={faShieldCat}
                className={`mr-2 ${rescue.sterilized ? 'text-green-500' : 'text-red-500'}`}
              />
              Esterilización: {rescue.sterilized ? 'Sí' : 'No'}
            </li>
            <li>
              <FontAwesomeIcon
                icon={rescue.sponsored ? faHeart : faHeartBroken}
                className={`mr-2 ${rescue.sponsored ? 'text-green-500' : 'text-red-500'}`}
              />
              Padrino: {rescue.sponsored ? 'Sí' : 'No'}
            </li>
            <li>
              <FontAwesomeIcon icon={faStethoscope} className="text-purple-500 mr-2" />
              Detalle veterinario: {rescue.surgery_details}
            </li>
            <li>
              <FontAwesomeIcon icon={faCat} className="text-purple-500 mr-2" />
              Personalidad: {rescue.personality}
            </li>
            <li>
              <FontAwesomeIcon icon={faBook} className="text-purple-500 mr-2" />
              Historia: {rescue.story}
            </li>
          </ul>
        </div>
      </div>
    </motion.div>
  );
};

export default RescueCard;
