import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; //"http://localhost:5000"// Obtenemos la URL del archivo .env *

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Middleware para incluir el token en las solicitudes protegidas
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Obtener configuraciones iniciales
export const getSettings = async () => {
  try {
    const response = await api.get('/api/settings');
    return response.data;
  } catch (error) {
    console.error('Error fetching settings:', error);
    throw error;
  }
};

// Crear nueva configuración
export const createSetting = async (settingData, imageFile) => {
  const formData = new FormData();
  Object.entries(settingData).forEach(([key, value]) => {
    formData.append(key, value);
  });
  if (imageFile) {
    formData.append('image', imageFile); // Incluye el archivo en la solicitud
  }

  try {
    const response = await api.post('/api/settings', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating setting:', error);
    throw error;
  }
};

// Actualizar configuración
export const updateSetting = async (id, settingData, imageFile) => {
  const formData = new FormData();
  Object.entries(settingData).forEach(([key, value]) => {
    formData.append(key, value);
  });
  if (imageFile) {
    formData.append('image', imageFile); // Incluye el archivo en la solicitud
  }

  try {
    const response = await api.put(`/api/settings/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating setting:', error);
    throw error;
  }
};

// Eliminar configuración
export const deleteSetting = async (id) => {
  try {
    const response = await api.delete(`/api/settings/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting setting:', error);
    throw error;
  }
};

// Obtener la lista de rescatados destacados
export const getHighlightedRescues = async () => {
  try {
    const response = await api.get('/api/rescues');

    // Ordenar los datos en el frontend por 'updated_at' en orden descendente
    const sortedRescues = response.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

    return sortedRescues;
  } catch (error) {
    console.error('Error fetching rescues:', error);
    throw error;
  }
};


// Obtener todos los rescatados
export const getRescues = async () => {
  try {
    const response = await api.get('/api/rescues');
    return response.data;
  } catch (error) {
    console.error('Error fetching all rescues:', error);
    throw error;
  }
};

// Crear nuevo rescatado
export const createRescue = async (rescueData, imageFile) => {
  const formData = new FormData();
  Object.entries(rescueData).forEach(([key, value]) => {
    formData.append(key, value);
  });
  if (imageFile) {
    formData.append('image', imageFile); // Incluye el archivo en la solicitud
  }

  try {
    const response = await api.post('/api/rescues', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating rescue:', error);
    throw error;
  }
};

// Actualizar rescatado
export const updateRescue = async (id, rescueData, imageFile) => {
  const formData = new FormData();
  Object.entries(rescueData).forEach(([key, value]) => {
    formData.append(key, value);
  });
  if (imageFile) {
    formData.append('image', imageFile); // Incluye el archivo en la solicitud
  }

  try {
    const response = await api.put(`/api/rescues/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating rescue:', error);
    throw error;
  }
};

// Eliminar rescatado
export const deleteRescue = async (id) => {
  try {
    const response = await api.delete(`/api/rescues/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting rescue:', error);
    throw error;
  }
};

// Función para iniciar sesión
export const login = async (credentials) => {
  try {
    const response = await api.post('/api/admin/login', credentials);
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

// Obtener todos los administradores
export const getAdmins = async () => {
  try {
    const response = await api.get('/api/admin');
    return response.data;
  } catch (error) {
    console.error('Error fetching admins:', error);
    throw error;
  }
};

// Crear nuevo administrador
export const createAdmin = async (adminData) => {
  try {
    const response = await api.post('/api/admin', adminData);
    return response.data;
  } catch (error) {
    console.error('Error creating admin:', error);
    throw error;
  }
};

// Eliminar administrador
export const deleteAdmin = async (id) => {
  try {
    const response = await api.delete(`/api/admin/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting admin:', error);
    throw error;
  }
};

// Enviar mensaje de contacto
export const sendContactMessage = async (contactData) => {
  try {
    const response = await api.post('/api/contact', contactData);
    return response.data;
  } catch (error) {
    console.error('Error sending contact message:', error);
    throw error;
  }
};

export default api;
