import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/LogoValkiria.png'; // Importa tu logo desde assets.

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para controlar el menú colapsable

  return (
    <nav className="bg-primary text-white fixed top-0 w-full shadow-md z-50">
      
      <section
      style={{
        background: 'linear-gradient(to right, #4a148c, #7b1fa2)',
      }}
      >
        <div className="container mx-auto flex justify-between items-center px-4 py-3">
          {/* Logo y Nombre */}
          <Link
            to="/"
            className="flex items-center text-white hover:text-violet-400 transition duration-300"
          >
            <img src={Logo} alt="Fundación Valkiria" className="h-10 w-auto mr-2" />
            <span className="text-lg font-bold">Fundación Valkiria</span>
          </Link>

          {/* Botón del Menú Hamburger */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-white md:hidden focus:outline-none focus:ring-2 focus:ring-white"
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>

          {/* Enlaces de Navegación */}
          <ul className={`md:flex space-x-6 hidden`}>
            <li>
              <Link
                to="/"
                className="text-violet-400 hover:text-purple-300 transition duration-300"
              >
                Inicio
              </Link>
            </li>
            <li>
              <Link
                to="/donation"
                className="text-violet-400 hover:text-purple-300 transition duration-300"
              >
                Donar
              </Link>
            </li>
            <li>
              <Link
                to="/rescued"
                className="text-violet-400 hover:text-purple-300 transition duration-300"
              >
                Rescatados
              </Link>
            </li>
            <li>
              <Link
                to="/login"
                className="text-violet-400 hover:text-purple-300 transition duration-300"
              >
                Iniciar Sesión
              </Link>
            </li>
          </ul>
        </div>

        {/* Menú Móvil */}
        {isMenuOpen && (
          <div className="md:hidden bg-primary">
            <ul className="flex flex-col space-y-2 px-4 py-3">
              <li>
                <Link
                  to="/"
                  className="text-violet-400 hover:text-purple-300 transition duration-300"
                  onClick={() => setIsMenuOpen(false)} // Cerrar menú al hacer clic
                >
                  Inicio
                </Link>
              </li>
              <li>
                <Link
                  to="/donation"
                  className="text-violet-400 hover:text-purple-300 transition duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Donar
                </Link>
              </li>
              <li>
                <Link
                  to="/rescued"
                  className="text-violet-400 hover:text-purple-300 transition duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Rescatados
                </Link>
              </li>
              <li>
                <Link
                  to="/login"
                  className="text-violet-400 hover:text-purple-300 transition duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Iniciar Sesión
                </Link>
              </li>
            </ul>
          </div>
        )}
      </section>
    </nav>
  );
};

export default Navbar;
