import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import RescuedPage from './pages/RescuedPage';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import DonationPage from './pages/DonationPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute'; // Componente para rutas protegidas

const App = () => {
  const location = useLocation();

  // Ocultar Navbar y Footer si estamos en /dashboard
  const isDashboardRoute = location.pathname === '/dashboard';

  return (
    <>
      {!isDashboardRoute && <Navbar />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/donation" element={<DonationPage />} />
        <Route path="/rescued" element={<RescuedPage />} />
        <Route path="/login" element={<LoginPage />} />
        {/* Rutas protegidas */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
      {!isDashboardRoute && <Footer />}
    </>
  );
};

// Envolver App con Router
const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
