import React from 'react';
import Home from '../components/Home';

const HomePage = () => {
  return (
    <div className="mt-16">
      <Home />
    </div>
  );
};

export default HomePage;
