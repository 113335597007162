import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUsers, faPaw, faCogs, faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Logo from '../assets/LogoValkiriaSquare.png';

const Sidebar = ({ setCurrentSection, isCollapsed, setIsCollapsed }) => {
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUsername(decodedToken.username || 'Usuario');
      } catch (err) {
        console.error('Error decoding token:', err);
        setUsername('Usuario');
      }
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div
      className={`bg-purple-800 text-white ${
        isCollapsed ? 'w-20' : 'w-64'
      } h-screen fixed top-0 left-0 transition-all duration-300 flex flex-col justify-between`}
    >
      {/* Logo y Título */}
      <div>
        <div className="flex items-center justify-between p-4">
          <img
            src={Logo}
            alt="Logo Fundación Valkiria"
            className={`transition-all duration-300 ${
              isCollapsed ? 'w-10 h-10 mx-auto' : 'w-12 h-12'
            }`}
          />
          {!isCollapsed && <h2 className="text-xl font-bold">Admin Panel</h2>}
          {/* Botón para colapsar/expandir */}
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className={`text-white p-2 rounded-full hover:bg-purple-700 ${
              isCollapsed ? 'mx-auto' : 'ml-2'
            }`}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className={`transition-transform ${
                isCollapsed ? 'rotate-180' : 'rotate-0'
              }`}
            />
          </button>
        </div>

        {/* Secciones */}
        <ul className="space-y-4 mt-6">
          <li>
            <button
              onClick={() => setCurrentSection('users')}
              className={`w-full flex items-center ${
                isCollapsed ? 'justify-center' : ''
              } gap-3 hover:bg-purple-700 p-2 rounded-lg`}
            >
              <FontAwesomeIcon icon={faUsers} />
              {!isCollapsed && <span>Usuarios</span>}
            </button>
          </li>
          <li>
            <button
              onClick={() => setCurrentSection('rescues')}
              className={`w-full flex items-center ${
                isCollapsed ? 'justify-center' : ''
              } gap-3 hover:bg-purple-700 p-2 rounded-lg`}
            >
              <FontAwesomeIcon icon={faPaw} />
              {!isCollapsed && <span>Casos</span>}
            </button>
          </li>
          <li>
            <button
              onClick={() => setCurrentSection('settings')}
              className={`w-full flex items-center ${
                isCollapsed ? 'justify-center' : ''
              } gap-3 hover:bg-purple-700 p-2 rounded-lg`}
            >
              <FontAwesomeIcon icon={faCogs} />
              {!isCollapsed && <span>Generales</span>}
            </button>
          </li>
        </ul>
      </div>

      {/* Usuario logeado */}
      <div className="mt-8 border-t border-purple-600 pt-4">
        <div
          className={`flex items-center ${
            isCollapsed ? 'justify-center' : 'gap-3'
          } mb-4`}
        >
          <FontAwesomeIcon icon={faUser} className="text-lg" />
          {!isCollapsed && <p className="font-semibold">{username}</p>}
        </div>
        <button
          onClick={handleLogout}
          className={`w-full flex items-center ${
            isCollapsed ? 'justify-center' : 'gap-3'
          } bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-lg`}
        >
          <FontAwesomeIcon icon={faSignOutAlt} />
          {!isCollapsed && <span>Cerrar Sesión</span>}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
