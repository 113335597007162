import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { getSettings } from '../services/api';

const Footer = () => {
  const [socialLinks, setSocialLinks] = useState({
    instagram: '',
    facebook: '',
    tiktok: '',
  });
  const [footer, setFooter] = useState({
    footerText: ''
  });

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const settings = await getSettings();
        const links = settings.reduce((acc, item) => {
          if (item.setting_key === 'instagram_link') acc.instagram = item.setting_value;
          if (item.setting_key === 'facebook_link') acc.facebook = item.setting_value;
          if (item.setting_key === 'tiktok_link') acc.tiktok = item.setting_value;
          return acc;
        }, {});
        setSocialLinks(links);

        const footerData = settings.reduce((acc, item) => {
          if(item.setting_key === 'footer-text') acc.footerText = item.setting_value;
          return acc;
        }, {});
        setFooter(footerData);
      } catch (error) {
        console.error('Error fetching social links:', error);
      }
    };

    fetchSocialLinks();
  }, []);

  return (
    <footer className="bg-primary text-grayLight py-0">
      
      <section
      className="py-8"
      style={{
        background: 'linear-gradient(to right, #4a148c, #7b1fa2)',
      }}
      >
        <div className="container mx-auto flex flex-col items-center space-y-4">
          {/* Social Media Links */}
          <div className="flex space-x-4">
            {socialLinks.instagram && (
              <a
                href={socialLinks.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-2xl hover:text-secondary transition"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            )}
            {socialLinks.facebook && (
              <a
                href={socialLinks.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-2xl hover:text-secondary transition"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            )}
            {socialLinks.tiktok && (
              <a
                href={socialLinks.tiktok}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-2xl hover:text-secondary transition"
              >
                <FontAwesomeIcon icon={faTiktok} />
              </a>
            )}
          </div>

          {/* Rights Section */}
          <div className="text-center text-sm">
            <p>{footer.footerText}</p>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
